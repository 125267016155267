import BaseBean from "@/utils/BaseBean";

export interface ITestContentDataObj {
    utilInst:TestContentUtil
    testContentParams:any
}
export default class TestContentUtil extends BaseBean{
    public dataObj:ITestContentDataObj;

    constructor(proxy:any,dataObj:ITestContentDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    public initTestContentParams(testContentParams:any):void{
    }
}